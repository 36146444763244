<template>
	<section class="mb-5">
		<div class="row">
			<div class="row mt-3">
				<div class="col-lg-6 col-12 lb pb-1 mt-1">
					<div class="row">
						<div class="col-12">
							<b>{{ $t('employeePrivate.assets') }}</b>
						</div>
						<div class="col-12">
							<InputText v-model="privateCondition.assets" :disabled="disabled"> </InputText>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-12 lb pb-1 mt-1">
					<div class="row">
						<div class="col-12">
							<b>{{ $t('employeePrivate.livingConditions') }}</b>
						</div>
						<div class="col-12">
							<select v-model="privateCondition.livingCondition" class="form-control" :disabled="disabled">
								<option value=""></option>
								<option privateCondition="Ejerbolig">
									{{ $t('employeePrivate.livingDropdown.owner') }}
								</option>
								<option privateCondition="lejebolig">
									{{ $t('employeePrivate.livingDropdown.renting') }}
								</option>
								<option privateCondition="andels">
									{{ $t('employeePrivate.livingDropdown.shared') }}
								</option>
							</select>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-12 lb pb-1 mt-1">
					<div class="row">
						<div class="col-12">
							<b>{{ $t('employeePrivate.equity') }}</b>
						</div>
						<div class="col-12">
							<InputText v-model="privateCondition.equity" :disabled="disabled"> </InputText>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-12 lb pb-1 mt-1">
					<div class="row">
						<div class="col-12">
							<b>{{ $t('employeePrivate.riskChance') }}</b>
						</div>
						<div class="col-12">
							<InputText v-model="privateCondition.riskChance" :disabled="disabled"> </InputText>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-12 lb pb-1 mt-1">
					<div class="row">
						<div class="col-12">
							<b>{{ $t('employeePrivate.debt') }}</b>
						</div>
						<div class="col-12">
							<InputText v-model="privateCondition.debt" :disabled="disabled"> </InputText>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-12 lb pb-1 mt-1">
					<div class="row">
						<div class="col-12">
							<b>{{ $t('employeePrivate.maritalStatus') }}</b>
						</div>
						<div class="col-12">
							<select v-model="privateCondition.maritalStatus" class="form-control" :disabled="disabled">
								<option value=""></option>
								<option value="Gift">
									{{ $t('employeePrivate.martialDropdown.married') }}
								</option>
								<option value="Samlevende">
									{{ $t('employeePrivate.martialDropdown.cohabiting') }}
								</option>
								<option value="Enlig">
									{{ $t('employeePrivate.martialDropdown.alone') }}
								</option>
							</select>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-12 lb pb-1 mt-1">
					<div class="row">
						<div class="col-12">
							<b>{{ $t('employeePrivate.beneficiary') }}</b>
						</div>
						<div class="col-12">
							<InputText v-model="privateCondition.beneficiary" :disabled="disabled"> </InputText>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-12 lb pb-1 mt-1">
					<div class="row">
						<div class="col-12">
							<b>{{ $t('employeePrivate.consent') }}</b>
						</div>
						<div class="col-12">
							<b-form-file :disabled="true"> </b-form-file>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-12 lb pb-1 mt-1">
					<div class="row">
						<div class="col-12">
							<b>{{ $t('employeePrivate.powerOfAttorney') }}</b>
						</div>
						<div class="col-12">
							<b-form-group>
								<b-form-radio class="d-inline mr-5" v-model="privateCondition.powerOfAttorney" value="true" :disabled="disabled">
									{{ $t('global.yes') }}
								</b-form-radio>

								<b-form-radio class="d-inline mr-5" v-model="privateCondition.powerOfAttorney" value="false" :disabled="disabled">
									{{ $t('global.no') }}
								</b-form-radio>
							</b-form-group>
						</div>
						<div class="col-12">
							<b>Personlig samarbejdsaftale</b>
						</div>
						<div class="col-12">
							<b-form-group>
								<b-form-radio class="d-inline mr-5" v-model="privateCondition.cooperation" value="true" :disabled="disabled">
									{{ $t('global.yes') }}
								</b-form-radio>

								<b-form-radio class="d-inline mr-5" v-model="privateCondition.cooperation" value="false" :disabled="disabled">
									{{ $t('global.no') }}
								</b-form-radio>
							</b-form-group>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-12 lb pb-	1 mt-1">
					<div class="row">
						<div class="col-12">
							<b>
								{{ $t('employeePrivate.powerOfAttorney') }}
								{{ $t('employeePrivate.pdf') }}
							</b>
						</div>
						<div class="col-12">
							<b-form-file :disabled="true"> </b-form-file>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-12 lb pb-1 mt-1">
					<div class="row">
						<div class="col-12">
							<label
								><b>{{ $t('employeePrivate.politicalExPerson') }}</b></label
							>
						</div>
						<div class="col-12">
							<InputCheck v-model="privateCondition.politicalExPerson" :disabled="disabled" />
						</div>
					</div>
				</div>

				
				<div class="col-12 mb-3 mt-3">
					<b-button class="btn-add" :disabled="disabled" @click="addChild">{{ $t('employeePrivate.childAdd') }}</b-button>
				</div>

				<template v-for="(child, index) in children">
					<div class="col-lg-6 col-12 lb pb-1 mt-1" :key="index">
						<div class="row">
							<div class="col-12">
								<b>{{ $t('employeePrivate.childName') }}</b>
							</div>
							<div class="col-12">
								<InputText v-model="child.name" :disabled="disabled"> </InputText>
							</div>
						</div>
					</div>

					<div class="col-lg-6 col-12 lb pb-1 mt-1" :key="child.index">
						<div class="row">
							<div class="col-12">
								<b>{{ $t('employeePrivate.childCpr') }}</b>
							</div>
							<div class="col-12">
								<InputNumber v-model="child.cpr" :disableThousandSeparator="true" :disabled="disabled"> </InputNumber>
							</div>
						</div>
					</div>
				</template>
			</div>

			<div class="row mt-3">
				<div class="col-12 mb-3">
					<b-button class="btn-add" :disabled="disabled" @click="addPartner">{{ $t('employeePrivate.partnerAdd') }}</b-button>
				</div>

				<template v-for="partner in partners">
					<div class="col-lg-6 col-12 lb pb-1 mt-1" :key="partner.index">
						<div class="row">
							<div class="col-12">
								<b>{{ $t('employeePrivate.partnerName') }}</b>
							</div>
							<div class="col-12">
								<InputText v-model="partner.name" :disabled="disabled"> </InputText>
							</div>
						</div>
					</div>

					<div class="col-lg-6 col-12 lb pb-1 mt-1" :key="partner.index">
						<div class="row">
							<div class="col-12">
								<b>{{ $t('employeePrivate.partnerCpr') }}</b>
							</div>
							<div class="col-12">
								<InputNumber v-model="partner.cpr" :disableThousandSeparator="true" :disabled="disabled"> </InputNumber>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</section>
</template>

<script>
import InputText from '@/modules/global/atomComponents/InputText'
import InputNumber from '@/modules/global/atomComponents/InputNumber'
import InputCheck from '@/modules/global/atomComponents/InputCheck'
import { superModel } from '@/models/superModel.js'
import InputDate from '@/modules/global/atomComponents/InputDate'
export default {
	name: 'PrivateCondition',
	components: {
		InputText,
		InputNumber,
		InputCheck,
		InputDate,
	},
	props: {
		value: Object,
		disabled: Boolean,
	},
	data() {
		return {}
	},
	computed: {
		privateCondition: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
		children() {
			return this.privateCondition.childrens
		},
		partners() {
			return this.privateCondition.partners
		},
	},
	methods: {
		async addChild() {
			var child = await superModel('personInfo', {})
			this.privateCondition.childrens.push(child)
		},
		async addPartner() {
			var person = await superModel('personInfo', {})
			this.privateCondition.partners.push(person)
		},
	},
}
</script>

<style></style>
