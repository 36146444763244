<template>
	<section class="container">
		<div class="row justify-content-end">
			<div class="col-auto">
				<BaseActionButton type="button" v-on:on-click="setEditable" v-if="$can('update', 'EmployeePolicy', 'PrivateCondition')">
					<font-awesome-icon :icon="['far', 'edit']" class="icon" />
				</BaseActionButton>
			</div>
		</div>
		<b-form @submit.prevent="onSubmit">
			<private-condition v-model="employeePolicy.privateCondition" :disabled="disabled" />
			<div class="row mb-5">
				<div class="col-12 p-0">
					<BaseActionButton class="btn-update" v-bind:clicked="clicked" v-bind:disabled="clicked" v-show="!disabled" type="submit" id="signupButton">
						{{ $t('global.update') }}
					</BaseActionButton>
				</div>
			</div>
		</b-form>
	</section>
</template>

<script>
import PrivateCondition from '../components/PrivateCondition.vue'
export default {
	name: 'EmployeePrivate',
	components: {
		'private-condition': PrivateCondition,
	},
	data() {
		return {
			clicked: false,
			disabled: true,
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		setEditable() {
			this.disabled = !this.disabled
		},
		async onSubmit() {
			console.log('UPDATING USER AND POLICY')
			this.clicked = true
			var employeePolicy = this.employeeInfo.policies.find((item) => {
				return (item.companyId = this.companyId)
			})
			var policyId = employeePolicy.policyId
			await this.$store.dispatch('employeeVuex/updateEmployeePolicy', {
				id: policyId,
				data: this.employeePolicy,
				companyId: this.companyId,
				employeeId: this.employeeId,
			})
			this.toast('Success', 'En medarbejder nu opdateret', true)
			this.clicked = false
		},
	},
	computed: {
		employeePolicy() {
			return this.$store.getters['employeeVuex/employeePolicy']
		},
		employeeInfo() {
			return this.$store.getters['employeeVuex/user']
		},
		companyId() {
			return this.$route.params.companyId
		},
		employeeId() {
			return this.$route.params.employeeId
		},
	},
}
</script>

<style></style>
